import React from 'react';
import '../style/steps-style.scss'
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import Slider from '../../../components/slider';
import STEPS from './steps-list';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'

const Steps = () => {
  const {t} = useTranslation();
  const {language} = React.useContext(I18nextContext);

  const Step = ({stepObj}) => (
    <div className="step-ctn" key={stepObj.title}>
      <div className="text-ctn">
        <h4 className="subtitle-text">{t(stepObj.subtitle)}</h4>
        <h3>{t(stepObj.title)}</h3>
        {
          stepObj.text !== undefined
            ?
            stepObj.text.map((text => (
              <p key={text}>{t(text)}</p>
            )))
            :
            (stepObj.customText)
        }
      </div>
      <div className="img-ctn">
        {stepObj.img}
      </div>
    </div>
  )

  let stepsTranslationsClass = "container"
  if (language !== 'pl')
    stepsTranslationsClass = "container en"

  return (
    <div className="steps-out-ctn">
      <div className={stepsTranslationsClass}>
        <p className="subtitle-text">{t`incubator.steps_subtitle`}</p>
        <h2>{t`incubator.steps_title1`}<br/>{t`incubator.steps_title2`}</h2>
        <Slider
          slidesToShow={1}
          slideToShowB2={1}
          infinite={false}
          autoplay={false}
          mobile850dots={false}
          nextArrow={<NextArrow/>}
          prevArrow={<PrevArrow/>}
        >
          {STEPS.map(step => <Step stepObj={step}/>)}
        </Slider>
      </div>
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} slide-arrow`}
      style={{ ...style}}
      onClick={onClick}
    >
      <FiArrowRight size={30} color="white"/>
    </button>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${className} slide-arrow`}
      style={{ ...style}}
      onClick={onClick}
    >
      <FiArrowLeft size={30} color="white"/>
    </button>
  );
}

export default Steps;
