import React from 'react';
import '../style/visit-us-style.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { BiTimeFive } from 'react-icons/bi'
import { StaticImage } from 'gatsby-plugin-image';


const VisitUs = () => {
  const {t} = useTranslation();

  return (
    <div className="visit-us-out-ctn" id="contact">
      <div className="container">
        <div className="visit-us-ctn">
          <h2>{t`incubator.visit_title`}</h2>
          <div className="label-ctn">
            <HiOutlineLocationMarker size={20}/>
            <p className="subtext">{t`incubator.visit_address`}</p>
          </div>
          <p className="text">{t`incubator.visit_address2`}</p>
          <div className="label-ctn" style={{marginTop: '2rem'}}>
            <BiTimeFive size={20}/>
            <p className="subtext">{t`incubator.visit_hours`}</p>
          </div>
          <p className="text">{t`incubator.visit_hours2`}</p>
        </div>
      </div>
      <StaticImage
        className="img"
        src="../../../images/incubator/visit-us-pointer.png"
        alt="Visit us"
        placeholder="none"
      />
    </div>
  );
}

export default VisitUs;
