import React from 'react';
import '../style/youth-support-style.scss'
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { HiOutlineLocationMarker } from 'react-icons/hi'

const YouthSupport = () => {
  const {t} = useTranslation();

  return (
    <div className="container youth-support-ctn">
      <div className="youth-ctn">
        <div>
          <h2>{t`incubator.youth_title`}</h2>
          <p>{t`incubator.youth1`}</p>
        </div>
        <StaticImage
          src="../../../images/incubator/support1.png"
          alt="Youth support image"
          placeholder="none"
        />
      </div>
      <div className="for-who-ctn">
        <StaticImage
          className="img-ctn"
          src="../../../images/incubator/support2.png"
          alt="Youth support image 2"
          placeholder="none"
        />
        <div>
          <h3>{t`incubator.youth2`}</h3>
          <p>{t`incubator.youth3`}</p>
        </div>
      </div>
      <div className="invite-ctn">
        <div>
          <h3>{t`incubator.youth4`}</h3>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <HiOutlineLocationMarker size={20}/>
            <p>{t`incubator.youth5`}</p>
          </div>
        </div>
        <StaticImage
          className="img-ctn"
          src="../../../images/incubator/support3.png"
          alt="Youth support image 3"
          placeholder="none"
        />
      </div>
    </div>
  );
}

export default YouthSupport;
