import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../style/preincubation-style.scss'
import { StaticImage } from 'gatsby-plugin-image';

const Preincubation = () => {
  const {t} = useTranslation();

  return (
    <div className="container preincubation-ctn">
      <StaticImage
        className="theme2"
        src="../../../images/incubator/theme/theme2.png"
        alt="Theme 2"
        placeholder="none"
      />
      <div className="title-ctn">
        <p className="subtitle">{t`incubator.preincubation`}</p>
        <h2>{t`incubator.what_more`}</h2>
      </div>
      <div className="benefits-ctn">
        <div className="benefit">
          <p className="number">1</p>
          <div>
            <h4>{t`incubator.test`}</h4>
            <p>{t`incubator.test1`}</p>
          </div>
        </div>
        <div className="benefit">
          <p className="number">2</p>
          <div>
            <h4>{t`incubator.space`}</h4>
            <p>{t`incubator.space1`}</p>
          </div>
        </div>
        <div className="benefit">
          <p className="number">3</p>
          <div>
            <h4>{t`incubator.cost`}</h4>
            <p>{t`incubator.cost2`}</p>
          </div>
        </div>
        <div className="benefit">
          <p className="number">4</p>
          <div>
            <h4>{t`incubator.mentor`}</h4>
            <p>{t`incubator.mentor1`}</p>
          </div>
        </div>
        <div className="benefit">
          <p className="number">5</p>
          <div>
            <h4>{t`incubator.help`}</h4>
            <p>{t`incubator.help1`}</p>
            <p>{t`incubator.help2`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preincubation;
