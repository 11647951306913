import React from 'react';
import '../style/preincubator-style.scss'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const Preincubator = () => {
  const {t} = useTranslation();

  const Step1 = () => (
    <div className="step-ctn">
      <div>
        <p className="step-number">1</p>
        <h3>{t`incubator.sign_in`}</h3>
      </div>
      <p>
        {t`incubator.sign_in1`}
        <a href="https://forms.gle/4hRb95Y9Tuhbsrt27" target="_blank" rel="noreferrer">
        {t`incubator.sign_in2`}
        </a>
        {t`incubator.sign_in3`}
        <Link to="/incubator#contact">{t`incubator.sign_in4`}</Link>
        {t`incubator.sign_in5`}
      </p>
    </div>
  )

  const Step2 = () => (
    <div className="step-ctn">
      <div>
        <p className="step-number">2</p>
        <h3>{t`incubator.analys`}</h3>
      </div>
      <p>
        {t`incubator.analys1`}
        <a href="https://en.wikipedia.org/wiki/Proof_of_concept" target="_blank" rel="noreferrer">
          {t`incubator.analys2`}
        </a>
        {t`incubator.analys3`}
        <a href="https://en.wikipedia.org/wiki/Minimum_viable_product" target="_blank" rel="noreferrer">
          {t`incubator.analys4`}
        </a>
        </p>
    </div>
  )

  const Step3 = () => (
    <div className="step-ctn">
      <div>
        <p className="step-number">3</p>
        <h3>{t`incubator.work`}</h3>
      </div>
      <p>
        {t`incubator.work1`}
      </p>
    </div>
  )

  const Step4 = () => (
    <div className="step-ctn">
      <div>
        <p className="step-number">4</p>
        <h3>{t`incubator.visual`}</h3>
      </div>
      <p>
        {t`incubator.visual1`}
      </p>
    </div>
  )

  const Step5 = () => (
    <div className="step-ctn">
      <div>
        <p className="step-number">5</p>
        <h3>{t`incubator.grow`}</h3>
      </div>
      <p>
        {t`incubator.grow1`}
      </p>
    </div>
  )

  return (
    <div className="container preincubator-ctn">
      <StaticImage
        className="theme1"
        src="../../../images/incubator/theme/theme1.png"
        alt="Theme 1"
        placeholder="none"
      />
      <h3>{t`incubator.preincubator_subtitle`}</h3>
      <h2>{t`incubator.preincubator_title`}</h2>
      <div className="steps-ctn">
        <div className="steps-ctn1">
          <Step1/>
          <Step3/>
          <Step5/>
        </div>
        <StaticImage
          className="rocket"
          src="../../../images/incubator/rocket.png"
          alt="Rocket"
          placeholder="none"
        />
        <div className="steps-ctn2">
          <Step2/>
          <Step4/>
        </div>
      </div>
      <div className="steps-ctn-mobile">
        <Step1/>
        <Step2/>
        <Step3/>
        <Step4/>
        <Step5/>
      </div>
    </div>
  );
}

export default Preincubator;
