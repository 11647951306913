import React from 'react';
import '../style/our-offer-style.scss'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { FiDownload } from 'react-icons/fi'

const OurOffer = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="container intro-offer-ctn" id="about">
        <div>
          <StaticImage
            src="../../../images/incubator/offer/document.png"
            alt="Document icon"
            placeholder="none"
            loading="eager"
          />
          <h4>{t`incubator.document1`} <span>{t`incubator.document2`}</span></h4>
        </div>
        <div>
          <StaticImage
            src="../../../images/incubator/offer/mvp.png"
            alt="MVP icon"
            placeholder="none"
            loading="eager"
          />
          <h4>{t`incubator.mvp1`} <span>{t`incubator.mvp2`}</span> {t`incubator.mvp3`}</h4>
        </div>
        <div>
          <StaticImage
            src="../../../images/incubator/offer/law.png"
            alt="Law icon"
            placeholder="none"
            loading="eager"
          />
          <h4>{t`incubator.law1`} <span>{t`incubator.law2`}</span></h4>
        </div>
        <div>
          <StaticImage
            src="../../../images/incubator/offer/ad.png"
            alt="Ad icon"
            placeholder="none"
            loading="eager"
          />
          <h4><span>{t`incubator.ad1`} </span>{t`incubator.ad2`}</h4>
        </div>
      </div>
      <div className="main-offer-ctn">
        <div className="container">
          <p className="subtitle-text">{t`incubator.our_offer`}</p>
          <h2>{t`incubator.build_with_us`}</h2>
          <p className="our-offer-about-text">{t`incubator.about_offer`}</p>
          <div className="offer-group-ctn">
            {OFFER_LIST.map(offer => (
              <SingleOffer object={offer}/>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

const OFFER_LIST = [
  {
    name: "incubator.graphic_offer",
    bullets: [
      "incubator.graphic_offer_bullet1",
      "incubator.graphic_offer_bullet2",
      "incubator.graphic_offer_bullet3",
      "incubator.graphic_offer_bullet4",
      "incubator.graphic_offer_bullet5"
    ],
    link: "/under-construction",
    icon: (
      <StaticImage
        src="../../../images/incubator/offer/graphic.png"
        alt="Graphic icon"
        placeholder="none"
        loading="lazy"
      />
    )
  },
  {
    name: "incubator.marketing_offer",
    bullets: [
      "incubator.marketing_offer_bullet1",
      "incubator.marketing_offer_bullet2",
      "incubator.marketing_offer_bullet3",
      "incubator.marketing_offer_bullet4",
      "incubator.marketing_offer_bullet5"
    ],
    link: "/under-construction",
    icon: (
      <StaticImage
        src="../../../images/incubator/offer/marketing.png"
        alt="Graphic icon"
        placeholder="none"
        loading="lazy"
      />
    )
  },
  {
    name: "incubator.hr_offer",
    bullets: [
      "incubator.hr_offer_bullet1",
      "incubator.hr_offer_bullet2",
      "incubator.hr_offer_bullet3",
      "incubator.hr_offer_bullet4",
      "incubator.hr_offer_bullet5"
    ],
    link: "/under-construction",
    icon: (
      <StaticImage
        src="../../../images/incubator/offer/hr.png"
        alt="Graphic icon"
        placeholder="none"
        loading="lazy"
      />
    )
  },
  {
    name: "incubator.dev_offer",
    bullets: [
      "incubator.dev_offer_bullet1",
      "incubator.dev_offer_bullet2",
      "incubator.dev_offer_bullet3",
      "incubator.dev_offer_bullet4",
      "incubator.dev_offer_bullet5",
      "incubator.dev_offer_bullet6"
    ],
    link: "/under-construction",
    icon: (
      <StaticImage
        src="../../../images/incubator/offer/dev.png"
        alt="Graphic icon"
        placeholder="none"
        loading="lazy"
      />
    )
  },
  {
    name: "incubator.business_offer",
    bullets: [
      "incubator.business_offer_bullet1",
      "incubator.business_offer_bullet2",
      "incubator.business_offer_bullet3",
      "incubator.business_offer_bullet4",
      "incubator.business_offer_bullet5",
      "incubator.business_offer_bullet6",
    ],
    link: "/under-construction",
    icon: (
      <StaticImage
        src="../../../images/incubator/offer/bussines.png"
        alt="Business icon"
        placeholder="none"
        loading="lazy"
      />
    )
  }
]

const SingleOffer = ({object}) => {
  const {t} = useTranslation();

  return (
    <div className="single-offer-ctn">
      <div>
        {object.icon}
        <h3>{t(object.name)}</h3>
      </div>
      {
        object.bullets.map(bullet => (
          <p>• {t(bullet)}</p>
        ))
      }
      <Link to={object.link}>
        {t`incubator.download-offer`}
        <FiDownload size={20}/>
      </Link>
    </div>
  )
}

export default OurOffer;
