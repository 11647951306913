import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import '../style/portfolio-style.scss'

const Portfolio = () => {
  const {t} = useTranslation();
  return (
    <div className="container">
      <h2 className="portfolio-title">{t`incubator.portfolio_text2`}</h2>
      <div className="portfolio-firms-ctn">
        <a href="https://fifteensecmedia.pl/" target="_blank">
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-sec.png"
            alt="SEC icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-sec.png"
            alt="SEC icon"
            placeholder="none"
            loading="lazy"
          />
        </a>
        <a href="https://www.sdconsulting.com.pl/" target="_blank">
            <StaticImage
              className="logo-gray"
              src="../../../images/incubator/portfolio/bw/logo-sd-consulting.png"
              alt="S&D Consulting icon"
              placeholder="none"
              loading="lazy"
            />
            <StaticImage
              className="logo-color"
              src="../../../images/incubator/portfolio/color/logo-sd-consulting.png"
              alt="S&D Consulting icon"
              placeholder="none"
              loading="lazy"
            />
        </a>
        <a href="https://diveindata.com/" target="_blank">
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-dive-in-data.png"
            alt="DiveInData icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-dive-in-data.png"
            alt="DiveInData icon"
            placeholder="none"
            loading="lazy"
          />
        </a>
        <a href="https://www.codenauts.io/" target="_blank">
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-codenauts.png"
            alt="Codenauts development studio logo"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-codenauts.png"
            alt="Codenauts development studio logo"
            placeholder="none"
            loading="lazy"
          />
        </a>
        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-fgi.png"
            alt="FGI Adsvisory icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-fgi.png"
            alt="FGI Adsvisory icon"
            placeholder="none"
            loading="lazy"
          />
        </div>
        <a href="https://consonance.tech/" target="_blank">
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-consonance.png"
            alt="Consonance icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-consonance.png"
            alt="Consonance icon"
            placeholder="none"
            loading="lazy"
          />
        </a>
        <a href="https://aureels.club/" target="_blank">
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-aureels.png"
            alt="AUREEL icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-aureels.png"
            alt="AUREEL icon"
            placeholder="none"
            loading="lazy"
          />
        </a>
      </div>
      <h2 className="portfolio-title">{t`incubator.portfolio_text`}</h2>
      <div className="portfolio-firms-ctn">
        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-airflo.png"
            alt="Airflo icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-airflo.png"
            alt="Airflo icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-cs.png"
            alt="CS icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-cs.png"
            alt="CS icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-efrp.png"
            alt="efrp icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-efrp.png"
            alt="efrp icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-innotech.png"
            alt="Innotech 4 life icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-innotech.png"
            alt="Innotech 4 life icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-metsphere.png"
            alt="Metsphere icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-metsphere.png"
            alt="Metsphere icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-philips.png"
            alt="PHILIPS icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-philips.png"
            alt="PHILIPS icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-pw.png"
            alt="Politechnika Warszawska icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-pw.png"
            alt="Politechnika Warszawska icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-plugin.png"
            alt="Plugin Foundation icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-plugin.png"
            alt="Plugin Foundation icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-play-air.png"
            alt="play.air icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-play-air.png"
            alt="play.air icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-aquinex.png"
            alt="AQUINEX IBERIA icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-aquinex.png"
            alt="AQUINEX IBERIA icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-techdays.png"
            alt="Polish Tech Days icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-techdays.png"
            alt="Polish Tech Days icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

        <div>
          <StaticImage
            className="logo-gray"
            src="../../../images/incubator/portfolio/bw/logo-optykbadura.png"
            alt="Optyk Badura icon"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="logo-color"
            src="../../../images/incubator/portfolio/color/logo-optykbadura.png"
            alt="Optyk Badura icon"
            placeholder="none"
            loading="lazy"
          />
        </div>

      </div>
    </div>
  );
}

export default Portfolio;
