import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';
import '../style/contact-us-style.scss'
import { StaticImage } from 'gatsby-plugin-image';

const ContactUs = () => {
  const {t} = useTranslation();

  return (
    <div className="contact-us-ctn">
      <StaticImage
        className="contact-img"
        src="../../../images/incubator/contact.png"
        alt="Contact us"
        placeholder="none"
      />
      <div className="container contact-inner-ctn">
        <h2>{t`incubator.contact_title`}</h2>
        <p>{t`incubator.contact_text`}</p>
        <Link to="/contact">{t`incubator.contact_btn`}</Link>
      </div>
    </div>
  );
}

export default ContactUs;
