import React from 'react';
import nextArrow from "../images/general/forward-icon.png";
import prevArrow from "../images/general/back-icon.png";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ctnStyle = {
  marginTop: '2rem',
  marginBottom: '2rem',
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  boxSizing: 'border-box',
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, width: '40px', height: '40px' }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next arrow" style={{width: '40px', position: 'absolute', left: 0, overflow: 'hidden'}}/>
    </button>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, width: '40px', height: '40px' }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="Prev arrow" style={{width: '40px', position: 'absolute', right: 0, overflow: 'hidden'}}/>
    </button>
  );
}

const Slider = (
  {
    children,
    className,
    slidesToShow=3,
    slideToShowB2=2,
    infinite = true,
    autoplay = true,
    mobile850dots = true,
    nextArrow = <SampleNextArrow />,
    prevArrow = <SamplePrevArrow />,
  }) => {

  const settings = {
    dots: true,
    infinite: infinite,
    lazyLoad: true,
    speed: 600,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    initialSlide: 0,
    autoplay: autoplay,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: slideToShowB2,
          slidesToScroll: slideToShowB2,
        }
      },
      {
        breakpoint: 850,
        settings: {
          dots: mobile850dots,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
  };

  return (
    <div style={ctnStyle} className={className}>
      <SlickSlider {...settings}>
        {children}
      </SlickSlider>
    </div>
  );
}

export default Slider;
