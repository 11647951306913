import { graphql } from "gatsby";
import IncubatorPage from '../views/incubator';

export default IncubatorPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
