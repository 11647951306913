import React from 'react';
import '../style/case-studies-style.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ProjectModal from '../../previous-editions/components/project-modal';
import {
  BREATHING_TAG, COSMETIC_SURGERY_TAG, COVID_TAG,
  GAMIFICATION_TAG,
  IT_TAG,
  MED_INNOVATIONS_TAG,
  SPEECH_THERAPY_TAG, VISUALISATION_TAG, WEB_SERVICES_TAG
} from '../../previous-editions/components/tags';

const PROJECTS = [
  {
    name: 'R-flow',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/Infografika_R-flow.jpg"
        alt="Projekt: R-flow"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Adrianna Pielech <span className="bullet">•</span> Karol Gałan</p>
        <p>Kamil Kozłowski <span className="bullet">•</span> Paweł Turowski</p>
      </>
    ),
    edition: 'previous-editions.project-2021-edition',
    position: 'previous-editions.project-2021-1-position',
    mentors: (
      <p>Ireneusz Martyniuk<span className="bullet">•</span> Invento Capital</p>
    ),
    tags: [IT_TAG, MED_INNOVATIONS_TAG, COVID_TAG],
    description: 'previous-editions.project-2021-1-description',
    trl_level: 'previous-editions.project-2021-1-trl_level',
    advantages_1: 'previous-editions.project-2021-1-advantages_1',
    advantages_2: 'previous-editions.project-2021-1-advantages_2',
    applications_1: 'previous-editions.project-2021-1-applications_1',
    applications_2: 'previous-editions.project-2021-1-applications_2',
    applications_3: 'previous-editions.project-2021-1-applications_3',
  },
  {
    name: 'play.air',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/play.air_infografika.png"
        alt="Projekt: play.air"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/play.air_infografika_eng.png"
        alt="Projekt: play.air"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Marcin Cackowski <span className="bullet">•</span> Piotr Strus</p>
        <p>Natalia Szczucka <span className="bullet">•</span> Łukasz Zaręba</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-1-position',
    mentors: (
      <p>Ireneusz Martyniuk<span className="bullet">•</span> Invento Capital</p>
    ),
    tags: [GAMIFICATION_TAG, BREATHING_TAG, SPEECH_THERAPY_TAG],
    description: 'previous-editions.project-2022-1-description',
    trl_level: 'previous-editions.project-2022-1-trl_level',
    advantages_1: 'previous-editions.project-2022-1-advantages_1',
    advantages_2: 'previous-editions.project-2022-1-advantages_2',
    advantages_3: 'previous-editions.project-2022-1-advantages_3',
    applications_1: 'previous-editions.project-2022-1-applications_1',
    applications_2: 'previous-editions.project-2022-1-applications_2',
    applications_3: 'previous-editions.project-2022-1-applications_3',
  },
  {
    name: 'BODI',
    img: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/BODI_infografika.png"
        alt="Projekt: BODI"
        placeholder="none"
        loading="lazy"
      />
    ),
    img_en: (
      <StaticImage
        className="modal-main-ctn2"
        src="../../../images/mentoring/portfolio/en/BODI_infografika_eng.png"
        alt="Projekt: BODI"
        placeholder="none"
        loading="lazy"
      />
    ),
    authors: (
      <>
        <p>Gabriela Kaczmarek <span className="bullet">•</span> Sara Kamrowska</p>
        <p>Mikołaj Pinkosz</p>
      </>
    ),
    edition: 'previous-editions.project-2022-edition',
    position: 'previous-editions.project-2022-2-position',
    mentors: (
      <p>Robert Stachurski<span className="bullet">•</span> GlucoActive</p>
    ),
    tags: [WEB_SERVICES_TAG, COSMETIC_SURGERY_TAG, VISUALISATION_TAG],
    description: 'previous-editions.project-2022-2-description',
    trl_level: 'previous-editions.project-2022-2-trl_level',
    advantages_1: 'previous-editions.project-2022-2-advantages_1',
    advantages_2: 'previous-editions.project-2022-2-advantages_2',
    advantages_3: 'previous-editions.project-2022-2-advantages_3',
    applications_1: 'previous-editions.project-2022-2-applications_1',
    applications_2: 'previous-editions.project-2022-2-applications_2',
    applications_3: 'previous-editions.project-2022-2-applications_3',
  },
]

const CaseStudies = () => {
  const [isModal, setModal] = React.useState(false);
  const [currentProject, setProject] = React.useState(PROJECTS[0])
  const {t} = useTranslation();

  return (
    <>
      <ProjectModal isModal={isModal} setIsModal={setModal} project={currentProject}/>
      <div className="container case-studies-ctn">
        <h2>{t`incubator.case`}</h2>
        <div>
          <button onClick={() => {
            setModal(true);
            setProject(PROJECTS[0])
          }}>
            <div className="case-img case-img1"/>
            <div className="text-ctn">
              <h4>R-flow</h4>
              <p>{t`incubator.case1`} <span>•</span> 10.06.2021</p>
            </div>
          </button>
          <button onClick={() => {
            setModal(true);
            setProject(PROJECTS[1])
          }}>
            <div className="case-img case-img2"/>
            <div className="text-ctn">
              <h4>play.air</h4>
              <p>{t`incubator.case2`} <span>•</span> 10.06.2022</p>
            </div>
          </button>
          <button onClick={() => {
            setModal(true);
            setProject(PROJECTS[2])
          }}>
            <div className="case-img case-img3"/>
            <div className="text-ctn">
              <h4>BODI</h4>
              <p>{t`incubator.case3`} <span>•</span> 10.06.2022</p>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

export default CaseStudies;
