import React from 'react';
import '../style/our-activities-style.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';


const OurActivities = () => {
  const {t} = useTranslation();

  return (
    <div className="main-offer-ctn our-activities-ctn">
      <StaticImage
        className="theme3"
        src="../../../images/incubator/theme/theme3.png"
        alt="Theme 3"
        placeholder="none"
      />
      <StaticImage
        className="theme4"
        src="../../../images/incubator/theme/theme4.png"
        alt="Theme 4"
        placeholder="none"
      />
      <div className="container">
        <p className="subtitle-text">{t`incubator.activities_subtitle`}</p>
        <h2>{t`incubator.activities_title1`}<br/>{t`incubator.activities_title2`}</h2>
        <div className="activity-group-ctn">
          <div className="activity">
            <h3>{t`incubator.activities1`}</h3>
            <p>{t`incubator.activities2`}</p>
          </div>
          <div className="activity">
            <h3>{t`incubator.activities3`}</h3>
            <p>{t`incubator.activities4`}</p>
          </div>
          <div className="activity">
            <h3>{t`incubator.activities5`}</h3>
            <p>{t`incubator.activities6`}</p>
          </div>
        </div>
        <div className="activity-group-ctn2">
          <div className="activity">
            <h3>{t`incubator.activities7`}</h3>
            <p>{t`incubator.activities8`}</p>
          </div>
          <div className="activity">
            <h3>{t`incubator.activities9`}</h3>
            <p>{t`incubator.activities10`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurActivities;
