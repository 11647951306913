import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {Trans} from 'gatsby-plugin-react-i18next';


const STEPS = [
  {
    subtitle: `incubator.step1_1`,
    title: `incubator.step1_2`,
    text: [
      `incubator.step1_3`,
      `incubator.step1_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step1.png"
        alt="Step 1"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step2_0`,
    title: `incubator.step2_1`,
    customText: (
      <>
        <p><Trans>incubator.step2_2</Trans></p>
        <p><Trans>incubator.step2_3</Trans></p>
        <p>
          <Trans>incubator.step2_4</Trans>
          <span><a href="mailto:hello@innovationsplatform.pl"><Trans>incubator.step2_5</Trans></a></span>
          <Trans>incubator.step2_6</Trans></p>
      </>
    ),
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step2.png"
        alt="Step 2"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step3_1`,
    title: `incubator.step3_2`,
    text: [
      `incubator.step3_3`,
      `incubator.step3_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step3.png"
        alt="Step 3"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step4_1`,
    title: `incubator.step4_2`,
    text: [
      `incubator.step4_3`,
      `incubator.step4_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step4.png"
        alt="Step 4"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step5_1`,
    title: `incubator.step5_2`,
    text: [
      `incubator.step5_3`,
      `incubator.step5_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step5.png"
        alt="Step 5"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step6_1`,
    title: `incubator.step6_2`,
    text: [
      `incubator.step6_3`,
      `incubator.step6_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step6.png"
        alt="Step 6"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step7_1`,
    title: `incubator.step7_2`,
    text: [
      `incubator.step7_3`,
      `incubator.step7_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step7.png"
        alt="Step 7"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step8_1`,
    title: `incubator.step8_2`,
    text: [
      `incubator.step8_3`,
      `incubator.step8_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step8.png"
        alt="Step 8"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step9_1`,
    title: `incubator.step9_2`,
    text: [
      `incubator.step9_3`,
      `incubator.step9_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step9.png"
        alt="Step 9"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step10_1`,
    title: `incubator.step10_2`,
    text: [
      `incubator.step10_3`,
      `incubator.step10_4`,
      `incubator.step10_5`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step10.png"
        alt="Step 10"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step11_1`,
    title: `incubator.step11_2`,
    text: [
      `incubator.step11_3`,
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step11.png"
        alt="Step 11"
        placeholder="none"
      />
    )
  },
  {
    subtitle: `incubator.step12_1`,
    title: `incubator.step12_2`,
    text: [
      `incubator.step12_3`,
      `incubator.step12_4`
    ],
    img: (
      <StaticImage
        src="../../../images/incubator/steps/step12.png"
        alt="Step 12"
        placeholder="none"
      />
    )
  },
]

export default STEPS;
