import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout/layout';
import './style/style.scss'
import OurOffer from './components/our-offer';
import Portfolio from './components/portfolio';
import Preincubator from './components/preincubator';
import Preincubation from './components/preincubation';
import ContactUs from './components/contact-us';
import YouthSupport from './components/youth-support';
import OurActivities from './components/our-activities';
import CaseStudies from './components/case-studies';
import VisitUs from './components/visit-us';
import Steps from './components/steps';
import scrollTo from "gatsby-plugin-smoothscroll";

const IncubatorPage = () => {
  const {t} = useTranslation();

  return (
    <Layout withHeader={false}>
      <Seo
        title={t('incubator.page-title')}
        description={t('incubator.page-title')}
        ogImage={ogImage}
      />
      <div className="incubator-header-ctn">
        <StaticImage
          className="header-img-desktop"
          src="../../images/incubator/header.png"
          alt={t`incubator.header-title`}
          placeholder="none"
          loading="eager"
        />
        <div className="container header-img-mobile">
          <StaticImage
            src="../../images/incubator/header-mobile.png"
            alt={t`incubator.header-title`}
            placeholder="none"
            loading="eager"
          />
        </div>
        <div className="header-text-ctn">
          <div className="container career-header-text-inner_ctn">
            <h1>{t`incubator.header-title`}</h1>
            <p>{t`incubator.header-text`}</p>
            <a href="https://forms.gle/4hRb95Y9Tuhbsrt27" target="_blank" rel="noreferrer">
            <button>{t`incubator.header-btn`}</button>
            </a>
          </div>
        </div>
      </div>

      <div className="incubator-offer">
        <OurOffer/>

        <Portfolio/>

        <Preincubator/>

        <Preincubation/>

        <Steps/>

        <ContactUs/>

        <YouthSupport/>

        <OurActivities/>

        <CaseStudies/>

        <VisitUs/>
      </div>
    </Layout>
  )
}

export default IncubatorPage;
